.tet_left img,
.tet_right img {
  width: 100%;
  height: auto;
}
.tet_left,
.tet_right {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 244px;
  pointer-events: none;
}
.tet_right {
  left: auto;
  right: 0;
  width: 250px;
}
.tet_bottom {
  position: fixed;
  bottom: 0;
  left: 0px;
  z-index: 99;
  width: 0px;
  pointer-events: none;
}
@media (max-width: 1284px) {
  .tet_left,
  .tet_right,
  .tet_bottom {
    display: none !important;
  }
}
