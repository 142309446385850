@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #0c0b10;
  background-color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
h6 {
  font-size: 14px;
}
a {
  text-decoration: none !important;
  outline: none !important;
}
p {
  line-height: 1.8;
}
.row > * {
  position: relative;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}
.navbar {
  padding: 15px 205px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar .navbar-nav .nav-item .nav-link {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  padding-right: 26px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.navbar .navbar-nav .nav-item .nav-link:hover,
.navbar .navbar-nav .nav-item .nav-link:focus,
.navbar .navbar-nav .nav-item .nav-link.active {
  /* color: rgb(162, 9, 55); */
  /* background-color: black; */
  color: white;

}
.navbar .navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0px !important;
}
.navbar .navbar-toggler {
  padding: 0px;
  border: none;
}
.navbar .navbar-toggler:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.navbar .navbar-toggler .navbar-toggle-icon {
  color: #fff;
}
.navbar .navbar-toggler .navbar-toggle-icon:focus {
  outline: none;
}
.navbar .navbar-brand .logo-light {
  display: block;
}
.navbar .navbar-brand .logo-dark {
  display: none;
}
.navbar .nav-btn {
  color: rgb(162, 9, 55);
  border: 1px solid #ebebeb;
  background-color: #fff !important;
}
.nav-sticky {
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 11, 16, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(12, 11, 16, 0.05);
}
.nav-sticky .navbar-nav .nav-item .nav-link {
  color: #61637b;
}
.nav-sticky .navbar-nav .nav-item .nav-link:hover,
.nav-sticky .navbar-nav .nav-item .nav-link:active,
.nav-sticky .navbar-nav .nav-item .nav-link:focus,
.nav-sticky .navbar-nav .nav-item .nav-link.active {
  color: rgb(162, 9, 55);
}
.nav-sticky .navbar-brand .logo-light {
  display: none;
}
.nav-sticky .navbar-brand .logo-dark {
  display: block;
}
.nav-sticky .nav-btn {
  color: #fff !important;
  border: 1px solid rgb(162, 9, 55);
  background-color: rgb(162, 9, 55) !important;
}
.nav-sticky .navbar-toggler {
  padding: 0px;
}
.nav-sticky .navbar-toggler .navbar-toggle-icon {
  color: #0c0b10;
}
.navbar-light {
  padding: 12px 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-light .navbar-nav .nav-item .nav-link {
  color: #a7a9c0;
}
.navbar-light .navbar-toggler {
  padding: 0px;
}
.navbar-light .navbar-toggler .navbar-toggle-icon {
  color: #0c0b10;
}
.navbar-light .navbar-brand .logo-light {
  display: none;
}
.navbar-light .navbar-brand .logo-dark {
  display: inline-block;
}
.logo-dark-mode {
  display: none;
}
@media (max-width: 991px) {
  .navbar {
    padding: 10px 0px;
    background-color: #fff;
    -webkit-box-shadow: 0px 4px 20px rgba(31, 27, 81, 0.07) !important;
    box-shadow: 0px 4px 20px rgba(31, 27, 81, 0.07) !important;
  }
  .navbar .navbar-nav {
    padding: 15px 0px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    color: #a7a9c0;
  }
  .navbar .navbar-brand .logo-light {
    display: none;
  }
  .navbar .navbar-brand .logo-dark {
    display: inline-block;
  }
  .navbar .navbar-toggler {
    padding: 0px;
  }
  .navbar .navbar-toggler .navbar-toggle-icon {
    color: #0c0b10;
  }
  .navbar .navbar-toggler:focus {
    outline: none !important;
  }
}
.btn {
  padding: 12px 20px;
  font-size: 13px;
  font-weight: 500;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.btn-sm {
  padding: 8px 12px;
  font-size: 12px;
}
.shadow {
  -webkit-box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.05) !important;
}
.bg-primary {
  background-color: rgb(162, 9, 55) !important;
}
.bg-soft-primary {
  background-color: rgba(64, 125, 160, 0.1) !important;
}
.text-primary {
  color: rgb(162, 9, 55) !important;
}
.border-primary {
  color: rgb(162, 9, 55) !important;
}
.btn-soft-primary {
  background-color: rgba(64, 125, 160, 0.25) !important;
  color: rgb(162, 9, 55) !important;
}
.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus,
.btn-soft-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:not(:disabled):not(.disabled):active:focus,
.btn-soft-primary .open > .dropdown-toggle.btn-primary {
  background: #3a7291;
}
.btn-primary {
  background: rgb(162, 9, 55);
  border-color: rgb(162, 9, 55) !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary .open > .dropdown-toggle.btn-primary {
  background: #e3104f;
  border-color: #e3104f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-primary {
  color: rgb(162, 9, 55);
  border-color: rgb(162, 9, 55);
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary .open > .dropdown-toggle.btn-primary {
  background: rgb(162, 9, 55);
  border-color: rgb(162, 9, 55);
}
.bg-secondary {
  background-color: #6c757d !important;
}
.bg-soft-secondary {
  background-color: rgba(108, 117, 125, 0.1) !important;
}
.text-secondary {
  color: #6c757d !important;
}
.border-secondary {
  color: #6c757d !important;
}
.btn-soft-secondary {
  background-color: rgba(108, 117, 125, 0.25) !important;
  color: #6c757d !important;
}
.btn-soft-secondary:hover,
.btn-soft-secondary:focus,
.btn-soft-secondary:active,
.btn-soft-secondary.active,
.btn-soft-secondary.focus,
.btn-soft-secondary:not(:disabled):not(.disabled):active,
.btn-soft-secondary:not(:disabled):not(.disabled):active:focus,
.btn-soft-secondary .open > .dropdown-toggle.btn-primary {
  background: #636b72;
}
.btn-secondary {
  background: #6c757d;
  border-color: #6c757d !important;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary .open > .dropdown-toggle.btn-primary {
  background: #636b72;
  border-color: #636b72;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary .open > .dropdown-toggle.btn-primary {
  background: #6c757d;
  border-color: #6c757d;
}
.bg-success {
  background-color: #47c07a !important;
}
.bg-soft-success {
  background-color: rgba(71, 192, 122, 0.1) !important;
}
.text-success {
  color: #47c07a !important;
}
.border-success {
  color: #47c07a !important;
}
.btn-soft-success {
  background-color: rgba(71, 192, 122, 0.25) !important;
  color: #47c07a !important;
}
.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success:active,
.btn-soft-success.active,
.btn-soft-success.focus,
.btn-soft-success:not(:disabled):not(.disabled):active,
.btn-soft-success:not(:disabled):not(.disabled):active:focus,
.btn-soft-success .open > .dropdown-toggle.btn-primary {
  background: #3eb570;
}
.btn-success {
  background: #47c07a;
  border-color: #47c07a !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success .open > .dropdown-toggle.btn-primary {
  background: #3eb570;
  border-color: #3eb570;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-success {
  color: #47c07a;
  border-color: #47c07a;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success .open > .dropdown-toggle.btn-primary {
  background: #47c07a;
  border-color: #47c07a;
}
.bg-info {
  background-color: #0dcaf0 !important;
}
.bg-soft-info {
  background-color: rgba(13, 202, 240, 0.1) !important;
}
.text-info {
  color: #0dcaf0 !important;
}
.border-info {
  color: #0dcaf0 !important;
}
.btn-soft-info {
  background-color: rgba(13, 202, 240, 0.25) !important;
  color: #0dcaf0 !important;
}
.btn-soft-info:hover,
.btn-soft-info:focus,
.btn-soft-info:active,
.btn-soft-info.active,
.btn-soft-info.focus,
.btn-soft-info:not(:disabled):not(.disabled):active,
.btn-soft-info:not(:disabled):not(.disabled):active:focus,
.btn-soft-info .open > .dropdown-toggle.btn-primary {
  background: #0cbadd;
}
.btn-info {
  background: #0dcaf0;
  border-color: #0dcaf0 !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info .open > .dropdown-toggle.btn-primary {
  background: #0cbadd;
  border-color: #0cbadd;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info .open > .dropdown-toggle.btn-primary {
  background: #0dcaf0;
  border-color: #0dcaf0;
}
.bg-warning {
  background-color: #f9c74f !important;
}
.bg-soft-warning {
  background-color: rgba(249, 199, 79, 0.1) !important;
}
.text-warning {
  color: #f9c74f !important;
}
.border-warning {
  color: #f9c74f !important;
}
.btn-soft-warning {
  background-color: rgba(249, 199, 79, 0.25) !important;
  color: #f9c74f !important;
}
.btn-soft-warning:hover,
.btn-soft-warning:focus,
.btn-soft-warning:active,
.btn-soft-warning.active,
.btn-soft-warning.focus,
.btn-soft-warning:not(:disabled):not(.disabled):active,
.btn-soft-warning:not(:disabled):not(.disabled):active:focus,
.btn-soft-warning .open > .dropdown-toggle.btn-primary {
  background: #f8c13b;
}
.btn-warning {
  background: #f9c74f;
  border-color: #f9c74f !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning .open > .dropdown-toggle.btn-primary {
  background: #f8c13b;
  border-color: #f8c13b;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-warning {
  color: #f9c74f;
  border-color: #f9c74f;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning .open > .dropdown-toggle.btn-primary {
  background: #f9c74f;
  border-color: #f9c74f;
}
.bg-danger {
  background-color: #f15230 !important;
}
.bg-soft-danger {
  background-color: rgba(241, 82, 48, 0.1) !important;
}
.text-danger {
  color: #f15230 !important;
}
.border-danger {
  color: #f15230 !important;
}
.btn-soft-danger {
  background-color: rgba(241, 82, 48, 0.25) !important;
  color: #f15230 !important;
}
.btn-soft-danger:hover,
.btn-soft-danger:focus,
.btn-soft-danger:active,
.btn-soft-danger.active,
.btn-soft-danger.focus,
.btn-soft-danger:not(:disabled):not(.disabled):active,
.btn-soft-danger:not(:disabled):not(.disabled):active:focus,
.btn-soft-danger .open > .dropdown-toggle.btn-primary {
  background: #f0421d;
}
.btn-danger {
  background: #f15230;
  border-color: #f15230 !important;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger .open > .dropdown-toggle.btn-primary {
  background: #f0421d;
  border-color: #f0421d;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-danger {
  color: #f15230;
  border-color: #f15230;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger .open > .dropdown-toggle.btn-primary {
  background: #f15230;
  border-color: #f15230;
}
.bg-dark {
  background-color: #0c0b10 !important;
}
.bg-soft-dark {
  background-color: rgba(12, 11, 16, 0.1) !important;
}
.text-dark {
  color: #0c0b10 !important;
  font-weight: bold;
}
.border-dark {
  color: #0c0b10 !important;
}
.btn-soft-dark {
  background-color: rgba(12, 11, 16, 0.25) !important;
  color: #0c0b10 !important;
}
.btn-soft-dark:hover,
.btn-soft-dark:focus,
.btn-soft-dark:active,
.btn-soft-dark.active,
.btn-soft-dark.focus,
.btn-soft-dark:not(:disabled):not(.disabled):active,
.btn-soft-dark:not(:disabled):not(.disabled):active:focus,
.btn-soft-dark .open > .dropdown-toggle.btn-primary {
  background: #030304;
}
.btn-dark {
  background: #0c0b10;
  border-color: #0c0b10 !important;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark .open > .dropdown-toggle.btn-primary {
  background: #030304;
  border-color: #030304;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-dark {
  color: #0c0b10;
  border-color: #0c0b10;
}
.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark .open > .dropdown-toggle.btn-primary {
  background: #0c0b10;
  border-color: #0c0b10;
}
.bg-muted {
  background-color: #8c8d96 !important;
}
.bg-soft-muted {
  background-color: rgba(140, 141, 150, 0.1) !important;
}
.text-muted {
  color: #000000 !important;
  
}
.border-muted {
  color: #8c8d96 !important;
}
.btn-soft-muted {
  background-color: rgba(140, 141, 150, 0.25) !important;
  color: #8c8d96 !important;
}
.btn-soft-muted:hover,
.btn-soft-muted:focus,
.btn-soft-muted:active,
.btn-soft-muted.active,
.btn-soft-muted.focus,
.btn-soft-muted:not(:disabled):not(.disabled):active,
.btn-soft-muted:not(:disabled):not(.disabled):active:focus,
.btn-soft-muted .open > .dropdown-toggle.btn-primary {
  background: #81828c;
}
.btn-muted {
  background: #8c8d96;
  border-color: #8c8d96 !important;
}
.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus,
.btn-muted:not(:disabled):not(.disabled):active,
.btn-muted:not(:disabled):not(.disabled):active:focus,
.btn-muted .open > .dropdown-toggle.btn-primary {
  background: #81828c;
  border-color: #81828c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-muted {
  color: #8c8d96;
  border-color: #8c8d96;
}
.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active,
.btn-outline-muted:not(:disabled):not(.disabled):active:focus,
.btn-outline-muted .open > .dropdown-toggle.btn-primary {
  background: #8c8d96;
  border-color: #8c8d96;
}
.bg-light {
  background-color: #f8f9fa !important;
}
.bg-soft-light {
  background-color: rgba(248, 249, 250, 0.1) !important;
}
.text-light {
  color: #f8f9fa !important;
}
.border-light {
  color: #f8f9fa !important;
}
.btn-soft-light {
  background-color: rgba(248, 249, 250, 0.25) !important;
  color: #f8f9fa !important;
}
.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active,
.btn-soft-light.active,
.btn-soft-light.focus,
.btn-soft-light:not(:disabled):not(.disabled):active,
.btn-soft-light:not(:disabled):not(.disabled):active:focus,
.btn-soft-light .open > .dropdown-toggle.btn-primary {
  background: #eceff2;
}
.btn-light {
  background: #f8f9fa;
  border-color: #f8f9fa !important;
}
.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.focus,
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light .open > .dropdown-toggle.btn-primary {
  background: #eceff2;
  border-color: #eceff2;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light .open > .dropdown-toggle.btn-primary {
  background: #f8f9fa;
  border-color: #f8f9fa;
}
.bg-body-bg {
  background-color: #525456 !important;
}
.bg-soft-body-bg {
  background-color: rgba(82, 84, 86, 0.1) !important;
}
.text-body-bg {
  color: #525456 !important;
}
.border-body-bg {
  color: #525456 !important;
}
.btn-soft-body-bg {
  background-color: rgba(82, 84, 86, 0.25) !important;
  color: #525456 !important;
}
.btn-soft-body-bg:hover,
.btn-soft-body-bg:focus,
.btn-soft-body-bg:active,
.btn-soft-body-bg.active,
.btn-soft-body-bg.focus,
.btn-soft-body-bg:not(:disabled):not(.disabled):active,
.btn-soft-body-bg:not(:disabled):not(.disabled):active:focus,
.btn-soft-body-bg .open > .dropdown-toggle.btn-primary {
  background: #484a4c;
}
.btn-body-bg {
  background: #525456;
  border-color: #525456 !important;
}
.btn-body-bg:hover,
.btn-body-bg:focus,
.btn-body-bg:active,
.btn-body-bg.active,
.btn-body-bg.focus,
.btn-body-bg:not(:disabled):not(.disabled):active,
.btn-body-bg:not(:disabled):not(.disabled):active:focus,
.btn-body-bg .open > .dropdown-toggle.btn-primary {
  background: #484a4c;
  border-color: #484a4c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-body-bg {
  color: #525456;
  border-color: #525456;
}
.btn-outline-body-bg:hover,
.btn-outline-body-bg:focus,
.btn-outline-body-bg:active,
.btn-outline-body-bg.active,
.btn-outline-body-bg.focus,
.btn-outline-body-bg:not(:disabled):not(.disabled):active,
.btn-outline-body-bg:not(:disabled):not(.disabled):active:focus,
.btn-outline-body-bg .open > .dropdown-toggle.btn-primary {
  background: #525456;
  border-color: #525456;
}
.bg-black {
  background-color: #000 !important;
}
.bg-soft-black {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.text-black {
  color: #000 !important;
}
.border-black {
  color: #000 !important;
}
.btn-soft-black {
  background-color: rgba(0, 0, 0, 0.25) !important;
  color: #000 !important;
}
.btn-soft-black:hover,
.btn-soft-black:focus,
.btn-soft-black:active,
.btn-soft-black.active,
.btn-soft-black.focus,
.btn-soft-black:not(:disabled):not(.disabled):active,
.btn-soft-black:not(:disabled):not(.disabled):active:focus,
.btn-soft-black .open > .dropdown-toggle.btn-primary {
  background: #000;
}
.btn-black {
  background: #000;
  border-color: #000 !important;
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active,
.btn-black.focus,
.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled):active:focus,
.btn-black .open > .dropdown-toggle.btn-primary {
  background: #000;
  border-color: #000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-black {
  color: #000;
  border-color: #000;
}
.btn-outline-black:hover,
.btn-outline-black:focus,
.btn-outline-black:active,
.btn-outline-black.active,
.btn-outline-black.focus,
.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.btn-outline-black .open > .dropdown-toggle.btn-primary {
  background: #000;
  border-color: #000;
}
.bg-white {
  background-color: #fff !important;
}
.bg-soft-white {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.text-white {
  color: #fff !important;
}
.border-white {
  color: #fff !important;
}
.btn-soft-white {
  background-color: rgba(255, 255, 255, 0.25) !important;
  color: #fff !important;
}
.btn-soft-white:hover,
.btn-soft-white:focus,
.btn-soft-white:active,
.btn-soft-white.active,
.btn-soft-white.focus,
.btn-soft-white:not(:disabled):not(.disabled):active,
.btn-soft-white:not(:disabled):not(.disabled):active:focus,
.btn-soft-white .open > .dropdown-toggle.btn-primary {
  background: #f5f5f5;
}
.btn-white {
  background: #fff;
  border-color: #fff !important;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.btn-white.focus,
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white .open > .dropdown-toggle.btn-primary {
  background: #f5f5f5;
  border-color: #f5f5f5;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white:active,
.btn-outline-white.active,
.btn-outline-white.focus,
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white .open > .dropdown-toggle.btn-primary {
  background: #fff;
  border-color: #fff;
}
.btn-custom-white {
  font-size: 13px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background: #fff;
  color: rgb(162, 9, 55) !important;
}
.btn-custom-white:hover,
.btn-custom-white:focus,
.btn-custom-white:active,
.btn-custom-white.active,
.btn-custom-white.focus,
.btn-custom-white:active,
.btn-custom-white:focus,
.btn-custom-white:hover,
.open > .dropdown-toggle.btn-custom-white {
  background: #f1f0f0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.bg-overlay {
  background-color: #000;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.section {
  padding: 80px 0px;
  position: relative;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-22 {
  font-size: 22px;
}
.fs-23 {
  font-size: 23px;
}
.fs-24 {
  font-size: 24px;
}
.fw-medium {
  font-weight: 500;
}
.fw-semibold {
  font-weight: 600;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.avatar-xs {
  height: 2rem;
  width: 2rem;
}
.avatar-s {
  width: 20px;
  height: 20px;
}
.avatar-sm {
  height: 32px;
  width: 32px;
}
.avatar-md {
  height: 60px;
  width: 60px;
}
.avatar-lg {
  height: 88px;
  width: 88px;
}
.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}
.card {
  background-color: #f8f9fa !important;
}
.bg-cta {
  background-image: url("../../../public/images/home/home-3.png");
  background-size: cover;
  background-position: center center;
  position: relative;
}
.mode-btn {
  position: fixed;
  color: #f8f9fa;
  width: 45px;
  height: 45px;
  top: 20%;
  right: 0px;
  z-index: 9999;
  padding: 9px;
  background-color: #396f8e;
  border: 1px solid #396f8e;
}
#mode .mode-dark {
  display: block;
}
#mode .mode-light {
  display: none;
}
.tobii button {
  display: none;
}
.home-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 250px 0px 200px;
}
.home-2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: relative;
}
.home-2 .registration-input-box {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.home-2 .registration-input-box:focus {
  border-color: rgb(162, 9, 55);
}
.home-3 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.home-4 .home-dashboard {
  position: relative;
  top: 50px;
  z-index: 1;
}
.home-slider {
  position: relative;
}
.home-slider .carousel-item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
@media (max-width: 991.98px) {
  .home-2 {
    height: auto;
    padding: 210px 0 150px 0;
  }
}
@media (max-width: 767.98px) {
  .home-2 {
    height: auto;
    padding: 150px 0 90px 0;
  }
}
@media (max-width: 991.98px) {
  .home-3 {
    height: auto;
    padding: 210px 0 150px 0;
  }
}
@media (max-width: 767.98px) {
  .home-3 {
    height: auto;
    padding: 150px 0 90px 0;
  }
}
@media (max-width: 575.98px) {
  .home-4 .home-dashboard {
    position: relative;
    top: 20px;
    z-index: 1;
  }
}
.blog-title {
  color: #0c0b10;
}
.blog-title:hover {
  color: rgb(162, 9, 55);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.filter-options li {
  font-size: 12px;
  border: 1px solid rgba(140, 141, 150, 0.2);
  padding: 0px 15px;
  margin: 5px 3px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  line-height: 30px;
  color: #8c8d96;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.filter-options li.active,
.filter-options li:hover {
  color: #fff;
  background-color: rgb(162, 9, 55);
  border: 1px solid rgb(162, 9, 55);
}
.item-box {
  overflow: hidden;
}
.item-box .item-container {
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
  -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.item-box:hover .item-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.service h3 {
  font-size: 14px;
  letter-spacing: 3px;
}
.service p {
  font-size: 30px;
}
.services-blog svg {
  max-width: 45px;
  float: left;
  margin-right: 20px;
  height: 48px;
  width: 48px;
  fill: rgba(255, 99, 72, 0.2);
  color: rgb(162, 9, 55);
}
.service-head {
  overflow: hidden;
}
.services-blog {
  padding: 40px;
  background-color: #fff;
}
.services-blog h4 {
  font-size: 16px;
}
.features-icon {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 54px;
  border: 2px solid rgba(12, 11, 16, 0.06);
  border-radius: 6px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.features-icon i {
  color: rgb(162, 9, 55) !important;
  line-height: 52px;
  font-size: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.features-icon:hover {
  background-color: rgb(162, 9, 55);
}
.features-icon:hover i {
  color: #fff !important;
}
.price {
  -webkit-box-shadow: 0px 4px 10px 0px rgba(12, 11, 16, 0.03);
  box-shadow: 0px 4px 10px 0px rgba(12, 11, 16, 0.03);
  border-radius: 12px;
  background-color: #fff;
}
.price .type h4 {
  font-size: 18px;
  text-transform: uppercase;
}
.price .value {
  position: relative;
}
.price .value h3 {
  display: inline-block;
  padding-right: 10px;
  font-size: 46px;
  position: relative;
}
.price .value h3 span {
  font-size: 14px;
  position: absolute;
  top: 5px;
}
.price .value .per {
  font-size: 13px;
}
.price .feature {
  padding: 15px 0;
}
.price .feature li {
  margin: 15px;
}
.price.active {
  background-color: rgba(255, 99, 72, 0.2);
}
.testi-box .testi-box-arrow::before {
  position: absolute;
  content: "\F6B0";
  font: normal normal normal 24px/1 "bootstrap-icons";
  top: -30px;
  font-size: 60px;
  color: rgb(162, 9, 55);
  left: 10px;
}
.tns-outer {
  position: relative;
}
.tns-outer .tns-controls {
  position: absolute;
  top: -50px;
  right: 0;
}
.tns-outer .tns-controls button {
  margin: 0 0 0px 5px;
  border-radius: 50%;
  border: none;
  background-color: rgba(64, 125, 160, 0.2) !important;
  color: rgb(162, 9, 55);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 18px;
}
.tns-outer .tns-controls button:focus,
.tns-outer .tns-controls button:hover,
.tns-outer .tns-controls button:active {
  outline: none;
  background-color: rgb(162, 9, 55) !important;
  color: #fff;
}
.tns-outer .tns-controls button.active button {
  background-color: #3d7799 !important;
}
.tns-outer .tns-controls:focus {
  outline: none !important;
}
.tns-outer .tns-nav {
  text-align: center;
  margin-top: 16px;
}
.tns-outer .tns-nav button {
  width: 10px !important;
  height: 10px !important;
  border: none;
  background-color: rgba(64, 125, 160, 0.6) !important;
  padding: 0px;
  margin: 5px 5px;
  border-radius: 20px;
}
.tns-outer .tns-nav button:focus,
.tns-outer .tns-nav button:hover,
.tns-outer .tns-nav button:active,
.tns-outer .tns-nav button.active {
  outline: none;
  background-color: rgb(162, 9, 55) !important;
}
.tns-outer .tns-nav button.tns-nav-active {
  background-color: rgb(162, 9, 55) !important;
}
.form-control {
  height: 46px;
  border: 1px solid rgba(140, 141, 150, 0.2);
  font-size: 14px;
  background-color: #f8f9fa;
  color: #0c0b10;
}
textarea.form-control {
  height: auto;
}
.form-control:focus {
  border-color: rgb(162, 9, 55);
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.address-info {
  overflow: hidden;
}
.error {
  margin: 8px 0px;
  display: none;
  color: #f15230;
}
#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0px;
}
.error_message {
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  border: 2px solid rgb(162, 9, 55);
  color: rgb(162, 9, 55);
  border-radius: 5px;
  font-size: 14px;
}
.contact-loader {
  display: none;
}
#success_page {
  text-align: center;
  margin-bottom: 50px;
}
#success_page h3 {
  color: #47c07a;
  font-size: 22px;
}
.custom-form .alert-warning {
  padding: 8px;
  color: #f15230;
  border: 2px solid #f15230;
  background-color: transparent;
  text-align: center;
}
.about-title {
  max-width: 800px;
}
.about-border-left {
  border-left: 1px solid rgba(140, 141, 150, 0.2);
}
.team-member {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  max-width: 165px;
}
.team-member:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
.footer {
  /* background-color: #0c0b10; */
  background-image: url("../../../public/images/home/footer.webp");
  padding: 80px 0 40px 0;
}
.footer .footer_mdi {
  font-size: 16px;
  height: 36px;
  width: 36px;
  line-height: 36px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin: 20px 2px;
}
.footer .footer_mdi.facebook {
  background-color: #4e71a8;
  color: #fff;
}
.footer .footer_mdi.twitter {
  background-color: #0dcaf0;
  color: #fff;
}
.footer .footer_mdi.google {
  background-color: #f15230;
  color: #fff;
}
.footer .footer_mdi.apple {
  background-color: #231f20;
  color: #fff;
}
.footer .footer_mdi.dribbble {
  background-color: #fff;
  color: #0c0b10;
}
.footer-alt {
  background-color: #17151f;
}
